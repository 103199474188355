.digital-twin-maximo-overview-content-data {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.digital-twin-maximo-value {
	font-weight: 400;
}

.digital-twin-maximo-overview-content-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: strech;
	width: 100%;
}
